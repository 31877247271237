/*//*/
*{box-sizing:border-box}
html,body,div,dl,dt,dd,ul,ol,li,h1,h2,h3,h4,h5,h6,pre,code,form,fieldset,legend,button,input,textarea,p,select,blockquote,th,td,img,a{margin:0;padding:0;font-weight:400;color:#38353A;font-family: 'Noto Sans KR', sans-serif, "Segoe UI Emoji", "Segoe UI Symbol";word-break: keep-all;word-wrap: break-word;font-size: 1em;line-height: 150%;letter-spacing: -0.02em}
html,body{font-size:16px;width:100%;height: 100%;overscroll-behavior: contain}
body.active{overflow-y: hidden}
body.loadingActive{overflow-y: hidden}
li{list-style:none}
a{text-decoration:none;color:#38353A}
a:hover{text-decoration:none}
a:active{text-decoration:none}
table{width:100%;border-collapse:collapse;border-spacing:0}
img{display:block;border:0}
button{background:transparent;border:0;cursor:pointer;outline:0}
input,select,textarea{border:1px solid #aaa;-webkit-appearance: none;border-radius: 0}
input[type="number"]::-webkit-outer-spin-button,input[type="number"]::-webkit-inner-spin-button{-webkit-appearance: none;margin: 0}
input[type="password"]{font-family: sans-serif}
input::placeholder{color:#7E7E7E;font-weight:500}
textarea::placeholder{color:#7E7E7E;font-weight:500}
input:focus,textarea:focus{outline: none;border:1px solid transparent !important;box-shadow: 0 0 0 2px #38353A inset}
select{-moz-appearance:none;-webkit-appearance:none;appearance:none}
select::-ms-expand{display:none}
textarea{-ms-overflow-style:none;scrollbar-width:none}
textarea::-webkit-scrollbar{display:none}
input[type="radio"],input[type="checkbox"]{display: none}
input.disabled{cursor: auto;opacity: 1;color:#797979;border:1px solid #4a4a4a}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active{-webkit-text-fill-color: #38353A;-webkit-box-shadow: 0 0 0 1000px white inset;box-shadow: 0 0 0 1000px white inset}
input:autofill,
input:autofill:hover,
input:autofill:focus,
input:autofill:active{-webkit-text-fill-color: #38353A;-webkit-box-shadow: 0 0 0 1000px white inset;box-shadow: 0 0 0 1000px white inset}

#root{width: 100%;overflow-x: hidden}
.pageSizing{min-height: calc(100vh - 17.214em - 60px)}

@keyframes err{
	0%,
	100% {
		transform: translateX(0);
	}

	12.5% {
		transform: translateX(12px);
	}

	25% {
		transform: translateX(-12px);
	}

	37.5% {
		transform: translateX(8px);
	}

	50% {
		transform: translateX(-8px);
	}

	62.5% {
		transform: translateX(5px);
	}

	75% {
		transform: translateX(-5px);
	}

	87.5% {
		transform: translateX(3px);
	}
}

@keyframes spin {
	to { -webkit-transform: rotate(360deg); }
}
@-webkit-keyframes spin {
	to { -webkit-transform: rotate(360deg); }
}

/* scroll*/
/* *{-ms-overflow-style: none;scrollbar-width: none}
*::-webkit-scrollbar{display: none} */
*::-webkit-scrollbar,
*::-webkit-scrollbar-thumb {height: 10px;max-width:10px;border-radius: 10px;background-clip: padding-box;border: 2px solid transparent}
*::-webkit-scrollbar-thumb {box-shadow: inset 0 0 0 4px #939393}
*::-webkit-scrollbar-track {background-color: #F9F8FA}

/*basic*/
.wra{width:100%;overflow: hidden}