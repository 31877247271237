@keyframes upDown{
  0%{
    transform: translateY(0);
  }
  50%{
    transform: translateY(50%);
  }
  100%{
    transform: translateY(0);
  }
}

@keyframes fadeUp{
  0%{
    opacity: 0;
    transform: translateY(6.875em)
  }
  100%{
    opacity: 1;
    transform: translateY(0)
  }
}

@keyframes fadeUp2n{
  0%{
    opacity: 0;
    transform: translateY(13.750em)
  }
  100%{
    opacity: 1;
    transform: translateY(6.875em)
  }
}

@keyframes fadeIn{
  0%{
    opacity: 0;
    transform: translateY(20%)
  }
  100%{
    opacity: 1;
    transform: translateY(0)
  }
}

@keyframes opacityIn{
  0%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
}

::selection{background:#FF6A29;color:#ffffff}

/*header*/
.header{height: 6.875em;position: fixed;top: 0;left: 0;width: 100%;z-index: 1000}
.header.subHeader{background-color: rgba(255,255,255,0.6);backdrop-filter: blur(6px)}
.headerSection{max-width: 1240px;margin: 0 auto;height: 100%;display: flex;align-items: center;justify-content: space-between;padding: 0 20px}
.btn_logo img{max-width: 11.625em;width: 100%}
.pcHeader_menuBox{display: flex;align-items: center;justify-content: flex-end}
.pcHeader_menu{color: #B3B2B4;font-size: 1.375em;font-weight: 700}
.pcHeader_menu.active{color: #1D1C1E}
.pcHeader_menu + .pcHeader_menu{margin: 0 0 0 1.818em}
.btn_mMenuBox,.mHeader_menuBox{display: none}
.header.wColor .pcHeader_menu{color: #807F81}
.header.wColor .pcHeader_menu.active{color: #fff}

/*main*/
.mainBanner{position: fixed;top: 0;left: 0;width: 100%;display: flex;align-items: center;justify-content: center;flex-direction: column;padding: 30px 20px;height: 100vh}
.mainBannerBg{background: url(/assets/images/img/main_bg.png) no-repeat center;background-size: cover;position: fixed;top: 0;left: 0;width: 100%;height: 100%}
.mainBanner>img{max-width: 11.250em;margin: 0 0 1em;position: relative;z-index: 1}
.mainBannerTitle{font-size: 2.800em;text-align: center;font-weight: 700;line-height: 1.5;position: relative;z-index: 1}
.btn_bannerBottomItem{width: 32px;position: fixed;bottom: 20px;left: 50%;transform: translateX(-50%);z-index: 3}
.btn_bannerBottomItem img{width: 100%;animation: upDown 1s infinite}
.mainSubBanner{opacity: -1;position: fixed;top: 0;left: 0;width: 100%;overflow: hidden;display: flex;align-items: center;justify-content: center;flex-direction: column;padding: 30px 20px;height: 100vh}
.mainSubBannerBg{position: absolute;top: 50%;left: 50%;width:100%;height: 100%;transform: translate(-50%,-50%);background: url(/assets/images/img/main_sub_bg.png) no-repeat center;background-size: cover}
.mainSubBanner_title{transform: scale(1.8);text-align: center;font-size: 1.4em;font-weight: 700;color: #fff;opacity: 0;position: relative;z-index: 1}
.mainSubBanner_title + .mainSubBanner_title{margin: 10px 0 0}
.mainSubBanner_title span{color:#FF6A29}
.mainContents{position: relative;z-index: 3;background: #fff}

.brandSlide_section{padding: 7.5em 0}
.brandSlide_title{text-align: center;padding: 0 20px;font-weight: 700;font-size: 2.500em}
.brandSlide_title span{color: rgba(255, 106, 41, 1)}
.brandSlide_area{margin: 6.25em 0 0;opacity: 0;transform: translateY(20%);transition: transform 0.4s, opacity 0.4s}
.brandSlide_section.active .brandSlide_area{transform: translateY(0);opacity: 1}
.brandSlideItem_on{display: none}
.swiper-slide-active .brandSlideItem_off{display: none}
.swiper-slide-active .brandSlideItem_on{display: block}
.brandSlideItem img{width:15.000em;margin: 0 auto}

.role_section{padding: 12.5em 20px}
.role_area{max-width: 1140px;margin: 0 auto;display: flex;align-items: flex-start;justify-content: space-between}
.role_titleArea{padding: 0 3.125em 0 0}
.mainTitle{font-size: 2.500em;font-weight: 700}
.titleDot{width: 12px;height: 12px;display: block;border-radius: 100%;background: #FF6A29;margin: 12px 0 0}
.role_itemArea{display: flex;align-items: flex-start;flex-wrap: wrap;max-width: 760px;gap: 3.125em}
.role_itemBox{width: calc(50% - 1.563em);background: #F8F7F9;border-radius: 22px;padding: 2.5em;opacity: 0;transform: translateY(6.875em);height: 28.125em}
.role_itemBox>img{margin: 0 auto;transition: margin 0.2s}
.role_itemTitle{color: #807F81;font-size: 1.875em;margin: 1.333em 0 0;font-weight: 700}
.role_itemText{font-weight: 500;font-size: 1.125em;margin: 0.889em 0 0;display: none;opacity: 0}
.role_section.active .role_itemBox:nth-child(1){animation: fadeUp 1s forwards}
.role_section.active .role_itemBox:nth-child(2){animation: fadeUp2n 1s 0.3s forwards}
.role_section.active .role_itemBox:nth-child(3){animation: fadeUp 1s 0.6s forwards}
.role_section.active .role_itemBox:nth-child(4){animation: fadeUp2n 1s 0.9s forwards}
.btn_role_itemText{font-weight: 500;text-align: center;width: 100%;border: 1px solid #1d1c1e;border-radius: 12px;height: 3.75em;margin: 2.5em 0 0}
.swiper-slide-active .role_itemBox{background: #FBE199}
.swiper-slide-active .role_itemBox>img{margin-top: -90px}
.swiper-slide-active .role_itemBox .role_itemTitle{color: #1D1C1E}
.swiper-slide-active .role_itemBox .role_itemText{display: block;animation: opacityIn 0.2s 0.2s forwards}
.role_slideBox{display: none;margin: 60px 0 0;width: 70%}
.role_slideBox .swiper{overflow: visible}
.role_slideBox .role_itemBox{width: 100%;transform: translateY(0) !important;opacity: 1 !important;height: 29.525em !important}
.custom_pagination {width: calc(100vw - 40px);display: flex;align-items: center;justify-content: center;margin: 30px 0 0}
.swiper-pagination-bullet{background: #D3D3D3;width: 6px;height: 6px;display: block;border-radius: 100px;margin: 0 0 0 6px}
.swiper-pagination-bullet-active{background: #38353A;width: 17px}

.newsList_sub{background: #F9F8FA;padding: 8.75em 20px;position: relative;z-index: 3}
.newsList_sub_titleBox{max-width: 1140px;margin: 0 auto}
.newsList_subList{margin: 5em auto 30px;max-width: 1140px;opacity: 0;transform: translateY(10%);transition: transform 0.3s, opacity 0.3s}
.newsList_sub.active .newsList_subList{opacity: 1;transform: translateY(0)}
.newsListBox{display: flex;flex-wrap: wrap;gap: 50px 30px}
.newsItem{width: calc(33.33% - 20px);background: #fff;border-radius: 16px;overflow: hidden;border: 1px solid rgba(29, 28, 30, 0.1)}
.newsImgBox{background-repeat: no-repeat;background-size: cover;background-position: center;width: 100%;padding: 0 0 61%;transition: padding 0.2s}
.newsTextBox{height:9.813em;border-top: 1px solid rgba(29, 28, 30, 0.1);padding: 1.875em;transition: height 0.2s;overflow: hidden;position: relative}
.newsInfoBox{display: flex;align-items: center}
.newsType{font-size: 0.875em;font-weight: 700;color: rgba(255, 106, 41, 1)}
.newsListDot{background: rgba(217, 217, 217, 1);display: block;width: 2px;height: 2px;border-radius: 100%;margin: 0 8px}
.newsDate{font-size: 0.875em;font-weight: 500;color: rgba(179, 178, 180, 1)}
.newsTitle{height:3em;font-size: 1.25em;font-weight: 700;margin: 0.75em 0 0;text-overflow: ellipsis;overflow: hidden;display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical}
.newsListBtn{font-size: 0.875em;font-weight: 500;width: 100%;margin: 2em 0 0;border-radius: 12px;border: 1px solid rgba(211, 211, 211, 1);height: 3.214em;display: none;position: absolute;bottom: 1.875em;left: 50%;transform: translateX(-50%);width: calc(100% - 1.875em - 1.875em)}
.newsListBtn:hover{background: rgba(29, 28, 30, 0.03);border-color: rgba(211, 211, 211, 1)}
/* .newsItem:hover .newsTitle{-webkit-line-clamp: 3} */
.newsItem:hover .newsListBtn{display: block}
.newsItem:hover .newsImgBox{padding: 0 0 30%;position: relative}
.newsItem:hover .newsImgBox::before{content: "";position: absolute;left: 0;top: 0;width: 100%;height: 100%;background: rgba(29, 28, 30, 0.1)}
.newsItem:hover  .newsTextBox{height: 16.750em;display: flex;flex-direction: column;justify-content: space-between}

.sectionBtn{transition: border 0.1s;font-weight: 500;display: flex;align-items: center;justify-content: center;gap: 10px;border: 1px solid rgba(29, 28, 30, 1);border-radius: 12px;width:100%;max-width: 320px;margin: 50px auto 0;height: 3.750em}
.sectionBtn:hover{border:2px solid rgba(255, 188, 0, 1)}
.openList{color: rgba(179, 178, 180, 1);font-size: 12px;font-weight: 600;width: 100%;max-width: 100px;margin: 0 auto;display: none}
.openList img{width: 19px;margin: 6px auto 0}
.bottomBtn_box{position: fixed;right: 3.75em;bottom: 3.75em;z-index: 998}
.bottomBtn_box img{width: 5.625em}
.bottomBtn{display: block}
.bottomBtn_copy{position: fixed;top: 0;left: 0;transform: translate(-200%,-200%)}
.discode_section{padding: 6.875em 20px 3.125em;background: url(/assets/images/img/discode_bg.png) no-repeat center;background-size: cover;background-color: #fff;position:relative;z-index: 3}
.discode_area{max-width: 360px;margin: 0 auto}
.discode_area>img{margin: 0 auto 20px;width: 4.5em}
.discode_title{font-size: 1.750em;font-weight: 600;text-align: center}
.discode_title span{color: rgba(114, 137, 218, 1)}
.discode_subText{color: rgba(179, 178, 180, 1);padding: 6px;background: rgba(249, 248, 250, 1);border-radius: 8px;width: 100%;max-width: 220px;font-size: 12px;margin: 25px auto 0;text-align: center}

/*footer*/
.footer{background: rgba(56, 53, 58, 1);z-index: 3;position: relative}
.footerSection{padding: 3.75em 20px}
.footerSection + .footerSection{border-top: 1px solid rgba(128, 127, 129, 0.4)}
.footerArea{max-width: 1140px;margin: 0 auto}
.footer_title{font-size: 1.375em;color: #fff;font-weight: 700}
.footer_linkBox{margin: 1.5em 0 0;display: flex;flex-wrap: wrap;justify-content: space-between;gap: 14px}
.footer_link{display: flex;align-items: center;font-size: 1.125em;color: rgba(211, 211, 211, 1)}
.footer_link_off{margin: 0 8px 0 0}
.footer_link_on{display: none;margin: 0 8px 0 0}
.footer_link_icon.footer_link_off{margin: 0 0 0 8px;transform: translateY(-7px)}
.footer_link_icon.footer_link_on{margin: 0 0 0 8px;display: none;transform: translateY(-7px)}
.footer_link:hover{color:#fff}
.footer_link:hover .footer_link_off{display: none}
.footer_link:hover .footer_link_icon.footer_link_off{display: none}
.footer_link:hover .footer_link_on{display: block}
.footer_link:hover .footer_link_icon.footer_link_on{display: block}
.footerText{color: rgba(179, 178, 180, 1);font-size: 13px;margin: 20px 0;max-width: 400px;line-height: 1.8}
.footerText a{margin: 0 8px;font-weight: 700;color: rgba(128, 127, 129, 1)}
.footerCaption{color: rgba(128, 127, 129, 1);font-size: 0.875em;font-weight: 500}

.footerAreaFlexBox{display: flex;align-items: flex-start;justify-content: space-between;gap: 30px}
.dropMenu{position: relative}
.btn_dropMenu{background: rgba(56, 53, 58, 1);border: 1px solid rgba(128, 127, 129, 1);display: flex;align-items: center;justify-content: space-between;color: rgba(211, 211, 211, 1);border-radius: 100px;font-size: 13px;padding: 10px 13px 12px 13px;gap: 12px;transition: color 0.2s}
.btn_dropMenu img{transition: transform 0.2s}
.btn_dropMenu.active img{transform: rotate(180deg)}
.btn_dropMenu:hover{color: #fff}
.btn_dropMenu.active{color: #fff}
.dropMenu_list{position: absolute;top: 51px;width: 100%;border: 1px solid rgba(128, 127, 129, 1);border-radius: 9px;display: none;background: rgba(56, 53, 58, 1);max-height: 150px;overflow-y: auto}
.dropMenu_list.acitve{display: block}
.dropMenu_linkItem{padding: 12px 14px;font-size: 13px;color: rgba(211, 211, 211, 1);display: flex;align-items: flex-start}
.dropMenu_linkItem:hover{color: #fff}
.dropMenu_icon{margin: 0 0 0 5px}
.dropMenu_icon_on{display: none}
.dropMenu_linkItem:hover .dropMenu_icon_on{display: block}
.dropMenu_linkItem:hover .dropMenu_icon_off{display: none}

/*about*/
.about_bg{height: 100vh;position: fixed;top: 0;left: 0;width: 100%}
.about_bg::before{content: "";background: url(/assets/images/img/about_bg_cover.png) no-repeat center;background-size: 100% 100%;width: 100%;height: 100%;position: absolute;top: 0;left: 0;z-index: 1}
.about_bg::after{content: "";background: rgba(29, 28, 30, 0.3);width: 100%;height: 100%;position: absolute;top: 0;left: 0;z-index: 2}
.about_bg video{width: 100%;height: 100%;object-fit: cover}

.aboutBanner{padding: 30px 20px;height: 100vh;display: flex;align-items: center;justify-content: center;position: relative;z-index: 2;position: fixed;top: 0;left: 0;width: 100%}
.aboutBanner_caption{color: rgba(255, 106, 41, 1);font-size: 1.25em;font-weight: 700;text-align: center;margin: 0 0 12px}
.aboutBanner_title{font-weight: 700;font-size: 2.8em;color: #fff;text-align: center}
.aboutBanner_text{text-align: center;font-size: 1.6em;color: #fff;font-weight: 600;line-height: 1.5}
.aboutBanner_0_textBox{margin: 9.375em 0 0}
.about_contents{background: #fff;position: relative;z-index: 3}
.aboutBanner_1_textBox{transform: translateY(20%);opacity: 0}
.aboutBanner_1.active .aboutBanner_1_textBox{transform: translateY(0);opacity: 1}

.aboutSection_0{padding: 8.75em 20px}
.aboutSection_titleBox{max-width: 1140px;margin: 0 auto}
.aboutSection_titleFlexBox{display: flex;align-items: flex-start;justify-content: space-between;gap: 30px}
.aboutSection_titleCaption{width: 66.66%;font-size: 1.375em;font-weight: 500}
.aboutSection_0_list{margin: 7.5em auto 0;max-width: 1140px;display: flex;justify-content: space-between;gap: 30px}
.aboutSection_0_item{width: calc(33.33% - 20px);border: 1px solid rgba(29, 28, 30, 0.1);border-radius: 16px;overflow: hidden;transform: translateY(20%);opacity: 0;transition: transform 0.3s, opacity 0.3s}
.aboutSection_0.active .aboutSection_0_item:nth-child(1){animation: fadeIn 0.3s forwards}
.aboutSection_0.active .aboutSection_0_item:nth-child(2){animation: fadeIn 0.3s 0.2s forwards}
.aboutSection_0.active .aboutSection_0_item:nth-child(3){animation: fadeIn 0.3s 0.4s forwards}
.aboutSection_0_img{background-repeat: no-repeat;background-position: center;background-size: 100%;width: 100%;padding: 0 0 52%;transition: background 0.2s}
.aboutSection_0_item:hover .aboutSection_0_img{background-size: 120%}
.aboutSection_0_text{font-size: 1.25em;font-weight: 700;text-align: center;padding: 20px 10px}
.aboutSection_0_slideBox{display: none}

.aboutSection_1{padding: 9.375em 20px}
.aboutSection_1_list{margin: 4.375em auto 0;max-width: 1140px;display: flex;align-items: flex-start;gap: 2.5em}
.aboutSection_1_listArea{display: flex;flex-wrap: wrap;gap: 2.5em;align-items: flex-start;width: calc(65% - 1.125em)}
.aboutSection_1_listArea:nth-child(2){width: calc(35% - 1.125em)}
.aboutSection_1_item{width: 100%;border-radius: 16px;overflow: hidden;position: relative;transform: translateY(20%);opacity: 0;transition: transform 0.3s, opacity 0.3s}
.aboutSection_1_img{background-repeat: no-repeat;background-position: center;background-size: 120%;transition: background 0.2s}
.aboutSection_1_item:hover .aboutSection_1_img{background-size: 140%}
.aboutSection_1_text{position: absolute;top: 1.364em;left: 1.364em;font-size: 1.375em;font-weight: 700;width: calc(100% - 1.364em - 1.364em)}
.aboutSection_1_listArea:nth-child(1) .aboutSection_1_item:nth-child(1){width:100%}
.aboutSection_1_listArea:nth-child(1) .aboutSection_1_item:nth-child(2){width: calc(55% - 1.125em)}
.aboutSection_1_listArea:nth-child(1) .aboutSection_1_item:nth-child(3){width: calc(45% - 1.38em)}
.aboutSection_1_listArea:nth-child(2) .aboutSection_1_item:nth-child(1){width:100%}
.aboutSection_1_listArea:nth-child(2) .aboutSection_1_item:nth-child(2){width:100%}
.aboutSection_1.active .aboutSection_1_listArea:nth-child(1) .aboutSection_1_item:nth-child(1){animation: fadeIn 0.3s forwards}
.aboutSection_1.active .aboutSection_1_listArea:nth-child(2) .aboutSection_1_item:nth-child(1){animation: fadeIn 0.3s 0.2s forwards}
.aboutSection_1.active .aboutSection_1_listArea:nth-child(1) .aboutSection_1_item:nth-child(2){animation: fadeIn 0.3s 0.4s forwards}
.aboutSection_1.active .aboutSection_1_listArea:nth-child(1) .aboutSection_1_item:nth-child(3){animation: fadeIn 0.3s 0.6s forwards}
.aboutSection_1.active .aboutSection_1_listArea:nth-child(2) .aboutSection_1_item:nth-child(2){animation: fadeIn 0.3s 0.8s forwards}
.aboutSection_1_slideBox{display: none}

.aboutSection_4{padding: 12.5em 20px}
.aboutSection_4_list{max-width: 1140px;margin: 6.875em auto 0;gap: 3.75em;display: flex;flex-wrap: wrap}
.aboutSection_4_item{width: calc(50% - 1.875em);transform: translateY(20%);opacity: 0}
.aboutSection_4_item img{width: 8.75em}
.aboutSection_4_textBox{margin: 1.875em 0 0}
.aboutSection_4_name{font-size: 1.375em;font-weight: 700}
.aboutSection_4_text{margin: 1em 0 0;font-size: 1.125em;font-weight: 500;color: rgba(128, 127, 129, 1)}
.aboutSection_4.active .aboutSection_4_item:nth-child(1){animation: fadeIn 0.3s forwards}
.aboutSection_4.active .aboutSection_4_item:nth-child(2){animation: fadeIn 0.3s 0.2s forwards}
.aboutSection_4.active .aboutSection_4_item:nth-child(3){animation: fadeIn 0.3s 0.4s forwards}
.aboutSection_4.active .aboutSection_4_item:nth-child(4){animation: fadeIn 0.3s 0.6s forwards}
.aboutSection_4.active .aboutSection_4_item:nth-child(5){animation: fadeIn 0.3s 0.8s forwards}

.aboutSection_3{padding: 8.75em 20px;background: rgba(249, 248, 250, 1)}
.aboutSection_3_list{max-width: 1140px;margin: 5em auto 0;display: flex;gap: 3.125em}
.aboutSection_3_tapBox{width: 100px;position: relative}
.aboutSection_3_tapBox::before{content: "";position: absolute;right: 0;top: 13px;width: 1px;height: 100%;background: rgba(217, 217, 217, 1)}
.aboutSection_3_tap{display: block;color: rgba(211, 211, 211, 1);font-size: 1.25em;font-weight: 700;position: relative;width: 100%;text-align: left;z-index: 1}
.aboutSection_3_tap + .aboutSection_3_tap{margin: 3em 0 0}
.aboutSection_3_tap.active{color: rgba(56, 53, 58, 1)}
.aboutSection_3_tap::before{content: "";position: absolute;right: -4.5px;top: 50%;transform: translateY(-50%);background: rgba(217, 217, 217, 1);width: 9px;height: 9px;border-radius: 100%}
.aboutSection_3_tap.active::before{border: 3px solid rgba(56, 53, 58, 1);background: #fff;right: -7px}
.aboutSection_3_item + .aboutSection_3_item{margin: 20px 0 0}
.aboutSection_3_date{color: rgba(128, 127, 129, 1);font-size: 0.938em;font-weight: 700;padding: 5px 8px;background: rgba(240, 240, 240, 1);border-radius: 6px;display: inline-block;margin: 0 0 10px}
.aboutSection_3_text{font-size: 0.938em;font-weight: 500}

.aboutSection_2{padding: 8.75em 20px}
.aboutSection_2_slideBox{max-width: 1140px;margin: 5em auto 0}
.aboutSection_2_img{background-repeat: no-repeat;background-size: cover;background-position: center;padding: 0 0 58%}
.aboutSection_2_text{display: flex;align-items: center;font-weight: 500;margin: 3px 0 0;justify-content: center;text-align: center}
.aboutSection_2_text img{margin: 0 10px 0 0}
.aboutSection_2 .custom_pagination{width:100%}
.aboutSection_2_itemName{font-size: 1.625em;font-weight: 700;margin: 0 0 20px;text-align: center}
.aboutSection_2_textBox{margin: 2.5em 0 0}
.aboutSection_2_textBox{display: none}
.swiper-slide-active .aboutSection_2_textBox{display: block}
.aboutSection_2_item{transform: scale(0.8);opacity: 0.4;transition: transform 0.3s, opacity 0.3s}
.swiper-slide-active .aboutSection_2_item{transform: scale(1);opacity: 1}
.aboutSection_2_slideBox .swiper{padding: 0 10.000em}
.aboutSection_2_slideBox_m{display: none}

/*news*/
.subPage{padding: 6.875em 0 0}
.newsSlide_section{background: #F9F8FA;height: 3.625em}
.newsSlide_area{display: flex;max-width: 1220px;padding: 0 20px;margin: 0 auto;height:100%;align-items: center}
.newsSlide_title{font-size: 0.875em;font-weight: 700;min-width: 2.605em}
.newsSlide_slideBox{height:100%}
.newsSlide_slideBox .swiper{height:100%}
.newsSlide_area>span{min-width: 2px;background: #B3B2B4;width: 2px;height: 2px;margin: 0 8px;display: block}
.newsSlide_item{font-weight: 500;font-size: 0.875em;text-align: left;height: 100%;text-overflow: ellipsis;overflow: hidden;white-space: nowrap}
.newsContents{padding: 3.125em 20px 9.375em;max-width: 1220px;margin: 0 auto}

.categoryItem{border-radius: 100px;font-weight: 500;color: rgba(128, 127, 129, 1);padding: 6px 12px;text-align: center;white-space: nowrap;border: 1px solid transparent}
.categoryItem.active{color: #fff;background: #38353A;font-weight: 700;border-color:#38353A}
.categoryItem:hover{border-color:#38353A}
.categoryItem + .categoryItem{margin: 0 0 0 8px}
.listCategory_section{margin: 4.063em 0 2.938em;display: flex;align-items: flex-start;justify-content: space-between;gap: 20px}
.categoryBox{display: flex;align-items: center;overflow-x: auto}
.listYear{justify-content: flex-end}
.notBg .categoryItem{color: #D3D3D3;margin: 0;position: relative;border:0}
.notBg .categoryItem.active{background: transparent;color: #38353a;font-weight:700}
.notBg .categoryItem:hover{color: #38353a}
.notBg .categoryItem + .categoryItem::before{content: "/";position: absolute;left: 0;top: 50%;transform: translate(-50%,-50%);color: #D3D3D3}
.notList{padding: 12.5em 0;text-align: center;width: 100%;color: #B3B2B4;font-weight: 500}
.pageNation{margin: 5.625em 0 0;display: flex;align-items: center;justify-content: center}
.btn_pageNation{padding: 2px 7px}
.btn_pageNation_prev{margin: 0 2.188em 0 0}
.btn_pageNation_next{margin: 0 0 0 2.188em}
.btn_pageNationNumber + .btn_pageNationNumber::before{content: "";position: absolute;left: 0;top: 50%;transform: translate(-50%,-50%);background: #D3D3D3;border-radius: 100%;width: 3px;height: 3px}
.btn_pageNationNumber{font-size: 1.625em;color: #B3B2B4;position: relative;padding: 0 13px}
.btn_pageNationNumber.active{color: #1D1C1E;font-weight: 700}
.bottomBtn + .bottomBtn{margin: 15px 0 0}
.m_year{display: none}

.newsDetail_naviBox{display: flex;align-items: center;gap: 9px}
.newsDetail_navi{display: flex;gap: 6px;font-size: 13px;color: #807F81;font-weight: 600}
.newsDetail_titleBox{margin: 1.875em 0 2.5em}
.newsDetail_title{font-size: 1.750em;font-weight: 700}
.newsDetail_info{margin: 3px 0 0;display: flex;align-items: center;gap: 8px}
.newsDetail_cate{font-size: 0.938em;color: #FF6A29;font-weight: 700}
.newsDetail_info>span{display: block;width: 2px;height: 2px;border-radius: 100%;background: #D9D9D9}
.newsDetail_date{font-size: 0.938em;color: #B3B2B4}
.newsDetail_contents{padding: 2.857em 0;border-top: 1px solid #F0F0F0;border-bottom: 1px solid #F0F0F0}
.newsDetail_text{font-size: 14px}
.newsDetail_subListBox{border: 1px solid #F0F0F0;border-radius: 16px;overflow: hidden;margin: 2.500em 0 0}
.newsDetailSubList{display: flex;align-items: center;gap: 20px;width:100%;text-align: left;padding: 1.25em}
.newsDetailSubList:hover{background: #F9F8FA}
.newsDetailSubList + .newsDetailSubList{border-top: 1px solid #F0F0F0}
.newsDetailSubList_type{display: flex;align-items: center;gap: 12px;min-width: 4.357em;font-size: 0.875em;font-weight: 700;color: #807F81}
.newsDetailSubList_infoBox{width: calc(100% - 3.813em - 20px)}
.newsDetailSubList_info{display: flex;align-items: center;gap: 8px}
.newsDetailSubList_cate{color: #B3B2B4;font-weight: 700;font-size: 0.813em}
.newsDetailSubList_infoBox>span{display: block;width: 2px;height: 2px;border-radius: 100%;background: #D9D9D9}
.newsDetailSubList_date{font-size: 0.813em;font-weight: 500;color: #B3B2B4}
.newsDetailSubList_title{font-size: 0.875em;font-weight: 500;text-overflow: ellipsis;overflow: hidden;margin: 2px 0 0;white-space: nowrap}      

/*careers*/
.careersIframe{width: 100%;height:calc(100vh - 6.875em + 72px);border: none;overflow: hidden;transform: translateY(-72px)}

/*반응형*/
@media (min-width:1024px){
  /*main*/
  .role_itemBox:hover{background: #FBE199}
  .role_itemBox:hover>img{margin-top: -90px}
  .role_itemBox:hover .role_itemTitle{color: #1D1C1E}
  .role_itemBox:hover .role_itemText{display: block;animation: opacityIn 0.2s 0.2s forwards}
  .role_itemBox:nth-child(2n){transform: translateY(13.750em)}
}
@media (max-height:465px){
  /*main*/
  .mainBannerTitle,.aboutBanner_title{font-size: 26px}
  .mainBanner>img{max-width: 120px;margin: 0 0 8px}
  /*about*/
  .aboutBanner_0_textBox{margin: 0}
  .aboutBanner_text{font-size: 16px}
  .aboutBanner_caption{font-size: 16px;margin: 0 0 7px}
}
@media (max-height:320px){
  /*about*/
  .aboutBanner_text{font-size: 14px !important}
  .aboutBanner_caption{font-size: 14px !important;margin: 0 0 4px}
}
@media (max-width:1800px){

}
@media (max-width:1680px){
	
}
@media (max-width:1600px){
    html, body{font-size:15px}
}
@media (max-width:1440px){
    html, body{font-size:14px}
}
@media (max-width:1366px){
    html, body{font-size:13px}
}
@media (max-width:1280px){
    html, body{font-size:12px}
}
@media (max-width:1152px){
    html, body{font-size:11px}
}
@media (max-width:1024px){
    html, body{font-size:13px}

    /*main*/
    .role_slideBox{display: block}
    .role_itemArea{display: none}
    .role_area{display: block}
}
@media (max-width:960px){
    .subPage{padding: 70px 0 0}
    /*header*/
    .header{height: 70px}
    .pcHeader_menuBox{display: none}
    .btn_mMenuBox,.mHeader_menuBox{display: block}
    .btn_mMenu{position: relative;width: 22px;height: 22px}
    .btn_mMenu span{background: rgba(29, 28, 30, 0.3);display: block;position: absolute;height: 2px;width: 100%;border-radius: 100px;transition: transform 0.3s, background 0.2s}
    .btn_mMenu span:nth-child(1){top: 5px}
    .btn_mMenu span:nth-child(2){bottom: 5px}
    .btn_mMenu.active span{background:#1D1C1E !important}
    .btn_mMenu.active span:nth-child(1){transform: translateY(5px) rotate(45deg)}
    .btn_mMenu.active span:nth-child(2){transform: translateY(-5px) rotate(-45deg)}
    .btn_mMenuBox{position: relative;z-index: 10}
    .mHeader_menuBox{z-index: 999;position: fixed;top: 0;right: -101%;width: 100%;height: 100vh;background-color: rgba(255,255,255,0.9);-webkit-backdrop-filter: blur(20px);backdrop-filter: blur(20px);padding: 110px 20px 40px;transition: right 0.3s}
    .mHeader_menuBox.active{right: 0}
    .mHeader_menu{display: block;width: 100%;text-align: left;color: #B3B2B4;font-weight: 700;font-size: 32px;position: relative}
    .mHeader_menu.active{color: #1D1C1E;padding-left: 23px}
    .mHeader_menu.active::before{content: "";position: absolute;background: #FF6A29;width: 7px;height: 7px;border-radius: 100%;top: calc(50% - 3.5px);left: 0}
    .mHeader_menu + .mHeader_menu{margin: 34px 0 0}
    .header.wColor .btn_mMenu span{background: rgba(255, 255, 255, 0.6)}

    /*main*/
    .mainBanner>img{max-width: 100px}
    .mainBannerTitle{font-size: 26px}

    .brandSlide_title{font-size: 22px}

    .newsListBox{gap: 20px}
    .newsItem{width:calc(50% - 10px)}

    .bottomBtn_box{right: 20px;bottom: 20px}
    .bottomBtn_box img{width: 50px}
    .bottomBtn_box.bottomBtnUp{bottom: 64px}

    /*footer*/
    .footerSection{padding: 30px 20px}
    .footer_title{font-size: 14px}
    .footer_link{font-size: 13px}
    .footer_link_off{display: none !important}
    .footer_link_on{display: block !important}
    .footer_link_off,.footer_link_on{width:20px}
    .footer_link_icon.footer_link_off,.footer_link_icon.footer_link_on{width:8px;transform: translateY(-6px)}
    .footerArea>img{width:73px}
    .footerText{font-size: 12px}
    .footerCaption{font-size: 12px}

    /*about*/
    .aboutBanner_title{font-size: 26px}
    .aboutBanner_text{font-size: 16px}

    .aboutSection_0,.aboutSection_1{padding: 90px 20px}

    .aboutSection_0_list{display: none}
    .aboutSection_0_slideBox{display: block;width: 100vw;transform: translateX(-20px);margin: 60px 0 0}
    .aboutSection_0_slideBox .swiper{padding: 0 20px}
    .aboutSection_titleFlexBox{display: block}
    .aboutSection_titleCaption{width: 100%;font-size: 16px;margin: 26px 0 0}
    .aboutSection_0_item{width:100%}

    .aboutSection_1_list{display: none}
    .aboutSection_1_slideBox{display: block;margin: 40px 0 0}
    .aboutSection_1.active .aboutSection_1_item{animation: fadeIn 0.3s forwards}
    .aboutSection_1_img{border-radius: 16px}
    .aboutSection_1_text{position: static;font-size: 18px;margin: 20px 0 0}

    .aboutSection_4{padding: 150px 20px}
    .aboutSection_4_item{width:calc(50% - 15px)}
    .aboutSection_4_list{gap:30px}
    .aboutSection_4_name{font-size: 16px}
    .aboutSection_4_text{font-size: 13px}

    .aboutSection_3_list{display: block;gap: 0;margin: 30px auto 0}
    .aboutSection_3_tapBox{width: 100%;display: flex;align-items: center}
    .aboutSection_3_tapBox::before{display: none}
    .aboutSection_3_tap{border-radius: 100px;width: 70px;font-weight: 500;font-size: 14px;color: rgba(128, 127, 129, 1);min-width: 70px;padding: 6px 0;text-align: center}
    .aboutSection_3_tap + .aboutSection_3_tap{margin: 0 0 0 8px}
    .aboutSection_3_tap.active{color: #fff;background: rgba(56, 53, 58, 1);font-weight: 700}
    .aboutSection_3_tap::before{display: none}
    .aboutSection_3_listBox{margin: 50px 0 0;position: relative}
    .aboutSection_3_listBox::before{content: "";position: absolute;left: 4px;top: 13px;width: 1px;height: 100%;background: rgba(217, 217, 217, 1)}
    .aboutSection_3_item{padding-left: 30px;position: relative}
    .aboutSection_3_item::before{content: "";position: absolute;left: 0;top: 10px;background: rgba(217, 217, 217, 1);width: 9px;height: 9px;border-radius: 100%}
    .aboutSection_3_tapBox{overflow-x: auto}

    .aboutSection_2{padding: 150px 20px}
    .aboutSection_2_slideBox .swiper{padding: 0}
    .aboutSection_2_textBox{margin: 30px 0 0}
    .aboutSection_2_itemName{font-size: 20px}
    .aboutSection_2_text{justify-content: flex-start;text-align: left}
    .aboutSection_2_item{transform: scale(1);opacity: 1}
    .aboutSection_2_textBox{display: block}
    .aboutSection_2_slideBox{display: none}
    .aboutSection_2_slideBox_m{display: block;margin: 40px 0 0}

    /*news*/
    .mainTitle{font-size: 22px}
    .titleDot{width:7px;height:7px}
    .btn_pageNation_prev{margin: 0 20px 0 0}
    .btn_pageNation_next{margin: 0 0 0 20px}
    .btn_pageNationNumber{font-size: 18px;padding: 0 10px}
    .pageNation img{width:7px}
    .listYear{display: none}
    .m_year{position: relative;display: block}
    .btn_yearOpen{width: 33px;height: 33px;min-width: 33px;background: url(/assets/images/basic/filter_off.svg) no-repeat center;background-size: 100%}
    .btn_yearOpen.active{background-image: url(/assets/images/basic/filter_on.svg)}
    .m_yearList{position: absolute;right: 0;top: 43px;border: 1px solid #D3D3D3;border-radius: 12px;min-width: 140px;background: #fff;display: none}
    .btn_m_yearCh{font-size: 14px;display: block;width: 100%;text-align: center;color: #d3d3d3;font-weight: 500;padding: 6px}
    .btn_m_yearCh.active{font-weight: 700;color: #38353A}

    .newsDetail_title{font-size: 18px}
    .newsDetail_date,.newsDetail_cate{font-size: 13px}
    .newsDetail_contents{padding: 25px 0}
    .newsDetail_titleBox{margin: 20px 0 25px}
    .newsDetail_subListBox{margin: 18px 0 0}

    /*careers*/
    .careersIframe{width: 100%;height:calc(100vh - 70px + 72px)}
}
@media (max-width: 720px){
    /*footer*/
    .dropMenu_list{top: inherit;bottom: 51px}
    .btn_dropMenu,.dropMenu,.dropMenu_box{width: 100%}
    .footerAreaFlexBox{flex-direction: column}
    .footer_linkBox{flex-direction: column}
}
@media (max-width: 650px){
    /*main*/
    .newsItem{width:100%;display: none}
    .newsItem:nth-child(-n+3){display: block}
    .openListItem .newsItem{display: block}
    .openListItem + .openList img{transform: rotate(180deg)}
    .openList{display: block}

    .sectionBtn.setMoPage{position: fixed;bottom: 0;left: 0;max-width: 100%;background-color: rgba(255, 188, 0, 1);border-color: rgba(255, 188, 0, 1);border-radius: 0;display: none}
    .btnActive .sectionBtn.setMoPage{display: flex}
}
@media (max-width: 550px){
}
@media (max-width: 490px){
    /*main*/
    .role_slideBox{width:100%}
}
@media (max-width: 400px){
}
@media (max-width: 340px){
}
/*//*/